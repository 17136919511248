<template>
	<div class="business">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="合作商家" name="first"></el-tab-pane>
			<el-tab-pane label="共享商家" name="second"></el-tab-pane>
		</el-tabs>
		<e-table ref="tables" @query-Table-data="handleCurrentChange" :tableCols="businessTableConf" v-on:resetSearch="resetSearch" v-on:searchParamsChange="searchParamsChange" :customSearchList="searchFormList" :showOperation="true" :dataOrigin="dataOrigin" :total="total" :currentPage="currentPage" :condition="form">
			<template #account1="scope">
				<div class="flex_r f_ai_c">
					<img height="60" width="60" :src="scope.rowData.avatar" alt="">
					<div class="pl10">
						<span>{{scope.rowData.username}}</span>
						<el-rate v-model="scope.rowData.rank" disabled></el-rate>
						<el-tag>{{scope.rowData.tag}}</el-tag>
					</div>
				</div>
			</template>
			<template #operation="scope">
				<div class="cur_p" @click="pj(scope.rowData)" v-on:handleOpen="openDetails">商家评级</div>
				<router-link tag="a" target="" :to="{path:'/detailPartner',query: {id:scope.rowData.id}}">查看数据</router-link>
				<div class="cur_p" @click="yq(scope.rowData.id)">邀请报名</div>
			</template>
		</e-table>
		<e-dialog title="评级" ref="dialogRef" v-on:confirm="confirm">
			<div class="tc">
				<el-rate v-model="rate"></el-rate>
				<el-input type="textarea" v-model="memo" placeholder="备注"></el-input>
			</div>
		</e-dialog>
	</div>
</template>

<script>
import {businessTableConf, searchFormList} from '@data/business'
export default {
	name: 'Business',
	data() {
		return {
			businessTableConf,
			searchFormList,
			dataOrigin: {
				data: []
			},
			total: 1,
			currentPage: 1,
			activeName: "first",
			form: {},
			rate: 0,
			memo: "",
			id: "",
			condition: {}
		}
	},
	methods: {
		handleCurrentChange(data){
			this.$refs.tables.loading = true
			this.currentPage = data.currentPage
			this.GetSellerLists()
		},
		pj(e){
			console.log(e)
			this.id = e.id
			// let formData = this.$refs.formRef.formData
			this.$refs.dialogRef.handleOpen()
		},
		resetSearch(){
			this.$refs.tables.loading = true
			this.form = {}
			this.condition = {}
			this.currentPage = 1
			this.GetSellerLists()
		},
		searchParamsChange(e) {
			this.$refs.tables.loading = true
			this.condition = e.condition
			this.currentPage = 1
			this.GetSellerLists()
		},
		openDetails(row){
			console.log(row)
		},
		yq(id){
			let that = this

			this.$confirm('是否邀请该商家报名?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.invite.user.handle.post',{
					id:id}).
				then(function () {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message({
						type: 'success',
						message: '邀请成功!'
					});
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消邀请'
				});
			});
		},
		confirm(){
			let that = this
			this.post('?server=live.seller.rank.handle.post',{
				note:this.memo,
				rank:this.rate,
				id:this.id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.$refs.dialogRef.handleClose()
				that.id = ''
				that.rate = 0
				that.memo = ''
				that.GetSellerLists()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		handleClick() {
			this.form = {}
			this.currentPage = 1
			this.$refs.tables.loading = true
			this.GetSellerLists()
		},
		GetSellerLists(){
			let that = this
			this.dataOrigin.data = []
			that.$refs.tables && that.$refs.tables.initData()
			this.currentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
			// console.log(that.dataOrigin)
			this.get('?server=live.seller.shop.lists.get',{
				page:this.currentPage,
				query:this.condition.query,
				rate:this.condition.rate}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response.data.total)
				let len = response.data.data.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.data[i])
				}
				that.total = response.data.total
				that.$refs.tables.total = response.data.total
				that.$refs.tables.loading = false
				that.$refs.tables.initData()
				// that.dataOrigin.data = response.data.data
				// console.log(that.total)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.$nextTick(() => {
			this.$refs.tables.loading = true
			this.GetSellerLists()
		});
	}
}
</script>